import 'core-js/stable';
import 'regenerator-runtime/runtime';

import objectFitImages from 'object-fit-images';
import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import IEPolyfills from './ie-polyfills';
const iePolyfills = new IEPolyfills();

import News from './news';
import Accordion from './accordion';
import Charts from './charts';
import Forms from './forms';
import Hero from './hero';
import Maps from './maps';
import Menu from './menu';
import Profiles from './profiles';
import Project from './project';
import ProjectFilter from './project-filter';
import Search from './search';
import { Sliders, HomeSlider } from './sliders';
import Tables from './tables';
import Tabs from './tabs';

new News();
new Accordion();
new Charts();
new Forms();
new Hero();
new HomeSlider();
new Maps();
new Menu();
new Profiles();
new Project();
new ProjectFilter();
new Search();
new Sliders();
new Tables();
new Tabs();

objectFitImages();