export default class Tabs {
  constructor() {
    $('.item-search__tab').on('click', (e) => {
      const $target = $(e.currentTarget)
      const $parent = $target.parents('.tabbed-content')

      $target
        .addClass('item-search__tab--active')
        .siblings()
        .removeClass('item-search__tab--active')

      const targetIndex = $target.index()
      $parent.find('> section').removeClass('active')
      $parent.find('> section').eq(targetIndex).addClass('active')
    })

    if (window.location.hash === '#portfolio') {
      const $target = $('.item-search__tab:contains("Portfolio")')
      const $parent = $target.parents('.tabbed-content')

      $target
        .addClass('item-search__tab--active')
        .siblings()
        .removeClass('item-search__tab--active')

      const targetIndex = $target.index()
      $parent.find('section').removeClass('active')
      $parent.find('section').eq(targetIndex).addClass('active')
    }
  }
}
