export default class Tables {
  constructor() {
    this.tables = $('.data-table table');

    if (this.tables.length > 0) {
      this.initMobileHeaders();
    }
  }

  initMobileHeaders() {
    this.tables.each((index, table) => {
      const tableObject = $(table);

      let headers = tableObject.find('tr').first().find('td');

      tableObject.find('td').each((index, row) => {
        const rowObject = $(row);

        if (rowObject.parent().index() !== 0 && rowObject.index() !== 0) {
          const headerText = headers.eq(rowObject.index()).text();
          rowObject.prepend(`<span class="mobile-data-header">${headerText}</span>`);
        }
      });
    })
  }
}