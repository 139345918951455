export default class ProjectFilter {
  constructor() {
    this.storiesToggle = $('#stories-toggle');
    this.featuredToggle = $('#featured-toggle');
    this.alphabeticalToggle = $('#az-toggle');

    this.alphabeticalNav = $('#alphabetical');

    this.stories = $('#stories');
    this.featuredProjects = $('#featured-projects');
    this.allProjects = $('#all-projects');

    this.loadMore = $('.projects__load-more:not(.load-stories)');
    this.loadMoreStories = $('.load-stories');

    this.storiesToggle.on('click', () => {
      this.showStories();
    });

    this.featuredToggle.on('click', () => {
      this.showFeatured();
    });

    this.alphabeticalToggle.on('click', () => {
      this.showAlphabetical();
    });

    this.letters = $('.item-search__alphabetical a');

    this.letters.on('click', (e) => {
      const target = $(e.currentTarget || e.target);
      this.filterByLetter(target);
    });

    if (location.hash === '#stories') {
      this.showStories();
      location.hash = '';
    }
  }

  showStories() {
    this.storiesToggle.addClass('item-search__tab--active');

    this.alphabeticalToggle.removeClass('item-search__tab--active');
    this.featuredToggle.removeClass('item-search__tab--active');
    this.alphabeticalNav.slideUp(400);

    this.allProjects.hide();
    this.featuredProjects.hide();
    this.stories.css('display', 'flex');

    this.loadMore.hide()
    this.loadMoreStories.show();
  }

  showFeatured() {
    this.featuredToggle.addClass('item-search__tab--active');

    this.storiesToggle.removeClass('item-search__tab--active');
    this.alphabeticalToggle.removeClass('item-search__tab--active');
    this.alphabeticalNav.slideUp(400);

    this.stories.hide();
    this.allProjects.hide();
    this.featuredProjects.css("display", "flex");

    this.loadMore.hide();
    this.loadMoreStories.hide();
  }

  showAlphabetical() {
    this.alphabeticalToggle.addClass('item-search__tab--active');

    this.storiesToggle.removeClass('item-search__tab--active');
    this.featuredToggle.removeClass('item-search__tab--active');
    this.alphabeticalNav.slideDown(400);

    this.stories.hide();
    this.featuredProjects.hide();
    this.allProjects.css("display", "flex");

    this.loadMore.show();
    this.loadMoreStories.hide();
  }

  filterByLetter(target) {
    this.letters.removeClass('active');
    target.addClass('active');
  }
}
