import Chartist from 'chartist';
import 'chartist-plugin-fill-donut';
import 'chartist/dist/chartist.min.css';

export default class Charts {
  constructor() {
    this.charts = $('.donut-canvas');

    this.charts.each((index, chart) => {
      this.initChart(chart);
    })
  }

  initChart(chart) {
    const loadedData = JSON.parse(chart.dataset.chart.replace(/'/g, '"'));

    if (loadedData) {
      let chartData = {
        series: []
      };

      let total = 0;

      loadedData.items.forEach((item) => {
        const itemValue = parseFloat(item.value.replace('%', ''));
        total += itemValue;

        chartData.series.push(itemValue);
      });

      const leftover = (100 - total).toFixed(1);

      if (leftover > 0) {
        chartData.series.push(parseFloat(leftover));
      }

      const centerLabel = `<h5>${loadedData.units}</h5><h6>units</h6>`;

      const chartOptions = {
        donut: true,
        donutWidth: 70,
        startAngle: 90,
        showLabel: false,
        plugins: [
          Chartist.plugins.fillDonut({
            items: [{
              content: centerLabel,
              position: 'center'
            }]
          })
        ]
      }

      const donut = new Chartist.Pie(`#${chart.id}`, chartData, chartOptions);

      const colors = this.getColors(chartData.series);

      donut.on('draw', (data) => {
        if (data.type === 'slice') {
          data.element.attr({
            'style': `stroke: ${colors[data.index]}`
          });

          $(`.data-chart__labels li:nth-of-type(${data.index + 1}) span`).css('background-color', colors[data.index]);
        }
      });
    }
  }

  getColors(series) {
    let aboveBase = series.length / 2,
      belowBase = series.length / 2;

    const base = '#0094d3';

    if (series.length % 2) {
      aboveBase = parseInt(aboveBase, 10);
      belowBase = parseInt(belowBase, 10);
    } else {
      belowBase -= 1;
    }

    const hex = String(base).replace(/[^0-9a-f]/gi, '');

    let beforeColors = [];
    let afterColors = [];

    for (let i = 0; i < aboveBase; i++) {
      let counter = i + 1,
        lum = -(counter * 0.5);

      const newColor = this.colorLuminance(hex, lum);
      afterColors.push(newColor);
    }

    for (var i = belowBase; i > 0; i--) {
      var lum = i * 0.5;
      var newColor = this.colorLuminance(hex, lum);
      beforeColors.push(newColor);
    }

    const colorArray = beforeColors.concat([base]).concat(afterColors);

    return colorArray;
  }

  colorLuminance(hex, lum) {
    let rgb = "#", c, i;
    for (i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i * 2, 2), 16);
      c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
      rgb += ("00" + c).substr(c.length);
    }

    return rgb;
  }
}