import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';

export default class Maps {
  constructor() {
    this.map = '';
    this.markers = [];

    this.initMaps();
  }

  initMaps() {
    mapboxgl.accessToken = 'pk.eyJ1IjoidXJhLXBpdHRzYnVyZ2giLCJhIjoiY2o5aGRuYXRxMzd3MTMycDkzNjgwMzF0ZCJ9.f9G60nJ79wKnOhejV1uFgA';

    if ($('.location__map').length > 0) {
      $('.location__map').each((i, mapContainer) => {
        const container = $(mapContainer);

        const latitude = container.data('latitude');
        const longitude = container.data('longitude');

        const mapId = container.find('.map').attr('id');
        const map = new mapboxgl.Map({
          container: mapId,
          style: 'mapbox://styles/ura-pittsburgh/cjci37jrq8sqy2slh94ezyheb',
          center: [longitude, latitude],
          zoom: 16,
          scrollZoom: false,
          dragPan: false,
          attributionControl: false
        });

        const nav = new mapboxgl.NavigationControl();
        map.addControl(nav, 'bottom-right');

        map.on('load', () => {
          const geojson = {
            type: 'FeatureCollection',
            features: [{
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [longitude, latitude]
              }
            }]
          };
          this.addMarkers(map, geojson, true);
        });
      });
    } else if ($('#map').length > 0) {
      this.map = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/ura-pittsburgh/cjci37jrq8sqy2slh94ezyheb',
        zoom: 16,
        maxZoom: 16,
        center: [-79.9959, 40.4406],
        attributionControl: false,
        scrollZoom: false
      });

      const nav = new mapboxgl.NavigationControl();
      this.map.addControl(nav, 'bottom-right');

      this.map.on('load', () => {
        let geojson = {
          type: 'FeatureCollection',
          features: []
        }

        let bounds = new mapboxgl.LngLatBounds();

        $('.address-data').each((i, card) => {
          const latitude = card.dataset.latitude,
            longitude = card.dataset.longitude;

          if (latitude && longitude) {
            geojson.features.push({
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [longitude, latitude]
              }
            });

            bounds.extend([longitude, latitude]);
          }
        });

        if (geojson.features.length) {
          this.map.fitBounds(bounds, {
            padding: { top: 56, bottom: 30, left: 38, right: 38 }
          });
        }

        this.addMarkers(this.map, geojson);
      });
    }
  }

  addMarkers(map, locations, largeIcon) {
    locations.features.forEach((marker) => {
      const element = document.createElement('div');
      const options = { offset: '' };
      if (largeIcon) {
        element.className = 'marker--large';
        options.offset = [14, -43];
      } else {
        element.className = 'marker--small';
        options.offset = [8, -26];
      }

      const MBmarker = new mapboxgl.Marker(element, options)
        .setLngLat(marker.geometry.coordinates)
        .addTo(map);

      this.markers.push(MBmarker);
    });
  }

}