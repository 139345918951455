export default class Profiles {
  constructor() {
    this.infoToggle = $('#more-info');
    this.details = $('.business-district-profiles__details');

    this.infoToggle.on('click', () => {
      if (this.infoToggle.hasClass('open')) {
        this.infoToggle.removeClass('open');
        this.infoToggle.text('MORE INFO');

        this.details
          .stop()
          .slideUp(400);
      } else {
        this.infoToggle.addClass('open');
        this.infoToggle.text('CLOSE');

        this.details
          .css('display', 'flex')
          .hide()
          .stop()
          .slideDown(400);
      }
    });
  }
}
