export default class News {
  constructor() {
    const NEWSCONTROLLER = $('.news-control');
    const PRESSCONTROLLER = $('.press-control');
    const NEWS = $('.posts');
    const PRESS = $('.press-releases');
    PRESS.hide();

    NEWSCONTROLLER.on('click', (e) => {
      if (!$(e).hasClass('selected')) {
        $('.selected').removeClass('selected');
        NEWSCONTROLLER.addClass('selected')
        NEWS.show();
        PRESS.hide();
      }
    });

    PRESSCONTROLLER.on('click', (e) => {
      if (!$(e).hasClass('selected')) {
        $('.selected').removeClass('selected');
        PRESSCONTROLLER.addClass('selected');
        PRESS.show();
        NEWS.hide();
      }
    });
  }
}