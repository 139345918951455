export default class Search {
  constructor() {
    this.rfpSearch = $('#rfp-search');
    this.pageSlug = this.rfpSearch.data('slug');

    this.clearField = $('#clear-search');

    this.rfpSearch.on('submit', (e) => {
      this.rfpFormSubmit(e);
    })

    this.rfpSearch.find('select').on('change', (e) => {
      this.rfpFormSubmit(e);
    })

    $('.item-search input[type="text"]').on('keyup', (e) => {
      const inputField = $(e.currentTarget);
      const inputValue = inputField.val();

      if (inputValue === '') {
        this.clearField.hide();
      } else {
        this.clearField.show();
      }
    });

    this.clearField.on('click', () => {
      this.clearField.hide();

      if (this.rfpSearch.length) {
        this.rfpSearch.find('input').val('');
        this.rfpFormSubmit();
      } else if (this.bdpSearch.length) {
        this.bdpSearch.find('input').val('');
        this.bdpFormSubmit();
      }
    });
  }

  rfpFormSubmit(e) {
    if (e) {
      e.preventDefault();
    }

    const q = this.rfpSearch.find('input[type="text"]').val();
    const category = $('#category').val();
    const status = $('#status').val();
    const type = $('#type').val();

    $.ajax({
      url: `/pages/${this.pageSlug}.json`,
      data: {
        q: q,
        category,
        status,
        request_type: type
      },
      success: (r) => {
        let requestsTemplate = '';
        if (Array.isArray(r)) {
          r.forEach(request => {
            requestsTemplate += `
              <a class="request-card" href="/proposals/${request.slug}">
                <div class="request-card__upper">
                  <div class="request-card__status">${request.status}</div>
                  <div class="request-card__type">${request.request_type}</div>
                </div>
                <div class="request-card__inner">
                  <div class="request-card__title">
                    <h2>${request.title}</h2>
                    <div class="request-card__learn-more">
                      <span role="link">LEARN MORE</span>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90.06 90.06">
                        <title>arrow_right</title>
                        <polygon points="53.35 27.56 48.3 32.07 56.69 41.47 25.58 41.47 25.58 48.24 57.13 48.24 48.42 57.99 53.47 62.5 69.02 45.1 53.35 27.56" fill="#0094d3"></polygon>
                        <path d="M45,90.06a45,45,0,1,1,45-45A45.08,45.08,0,0,1,45,90.06ZM45,6.78A38.25,38.25,0,1,0,83.29,45,38.3,38.3,0,0,0,45,6.78Z" fill="#0094d3"></path>
                      </svg>
                    </div>
                  </div>
                  <div class="request-card__details">`;

            if (request.details) {
              requestsTemplate += `
                <div class="request-info">
                  <div class="request-info__info">
                    <label class="request-info__label">${request.details[0].label}</label>

                    <div class="request-info__detail">${request.details[0].info}</div>
                  </div>`
                  if (request.details[1]) {
                    requestsTemplate += `
                    <div class="request-info__info">
                      <label class="request-info__label">${request.details[1].label}</label>

                      <div class="request-info__detail">${request.details[1].info}</div>
                    </div>`
                  }
              requestsTemplate += `</div>`;
            }

            if (request.category) {
              requestsTemplate += `
                <div class="request-info request-card__category">
                  <div class="request-info__info">
                    <label class="request-info__label">Category</label>
                    <div class="request-info__detail">${request.category}</div>
                  </div>
                </div>`;
            }

            requestsTemplate += `
                  </div>
                </div>
              </a>`;

            $('.item-search__results').html(requestsTemplate);
          });
        } else {
          $('.item-search__results').html('');
        }
      }
    })
  }
}