export default class Hero {
  constructor() {
    this.button = $('#page-down');

    this.button.on('click', () => {
      this.scrollPage();
    });

    this.videoPlay = $('.featured-media__video-link');
    this.videoClose = $('.featured-media__video-close');

    this.videoPlay.on('click', (e) => {
      const target = $(e.currentTarget);
      const parent = target.parents('.featured-media');

      parent.find('.featured-media__video').addClass('show');
    });

    this.videoClose.on('click', (e) => {
      const target = $(e.currentTarget);
      const parent = target.parents('.featured-media');

      parent.find('.featured-media__video').removeClass('show');
    });
  }

  scrollPage() {
    const hero = this.button.parents('.hero');
    const nextItem = hero.next();

    $('html, body').animate({ scrollTop: nextItem.offset().top }, 600);
  }
}