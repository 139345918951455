import 'jquery-hoverintent';

export default class Menu {
  constructor() {
    this.header = $('header');
    this.primaryNav = $('.primary-nav');
    this.rootLinks = this.primaryNav.find('> li > a');
    this.scrollToTop();

    this.initHeight = 167;
    this.shrinkHeight = 70;

    if ($(window).width() < 1110) {
      this.initHeight = 70;
    }

    $(window).on('resize', () => {
      this.initHeight = 167;
      this.shrinkHeight = 70;

      if ($(window).width() < 1110) {
        this.initHeight = 70;
      }
    });

    this.menuToggle = $('.menu-toggle');
    this.childrenToggle = $('.children-toggle');
    this.smallLogo = $('.logo--small');

    $('a, button').on('focus', (e) => {
      if ($(e.currentTarget).parents('.primary-nav li').find('ul').length === 0) {
        this.endLinkFocus();
        this.closeSubNavs();
      }
    });

    const $window = $(window);

    this.rootLinks.on('focus', (e) => {
      if ($window.width() >= 1110) {
        this.linkHover(e);

        if ($(e.currentTarget).parents('.primary-nav li').find('ul').length === 0) {
          this.closeSubNavs();
        }
      }
    });

    this.primaryNav.hoverIntent(
      () => false,
      () => {
        if ($window.width() >= 1110) {
          this.endLinkFocus();
          this.closeSubNavs();
        }
      }
    );

    this.header.hoverIntent({
      over: (e) => {
        if ($window.width() >= 1110) {
          this.linkHover(e);

          if ($(e.currentTarget).parents('.primary-nav li').find('ul').length === 0) {
            this.closeSubNavs();
          }
        }
      },
      out: () => false,
      selector: '.primary-nav > li > a',
      sensitivity: 10
    });

    this.primaryNav.find('> li ul a').on('focus', (e) => {
      const target = $(document.activeElement);
      const parentLink = target.parents('.primary-nav li').find('> a');
      this.linkHover('', parentLink);
    });

    let cachedWidth = $(window).width();
    $(window).on('resize', () => {
      const newWidth = $(window).width();

      if (newWidth !== cachedWidth) {
        this.headerReset();
        cachedWidth = newWidth;
      }
    });

    this.menuToggle.on('click', () => {
      this.toggleMobileMenu();
    });

    this.childrenToggle.on('click', (e) => {
      this.toggleMobileChildren(e);
    });

    this.scrollEventInit();
  }

  linkHover(event, parent) {
    this.header.scrollTop(0);

    let target = '';

    if (parent) {
      target = parent;
    } else {
      target = $(event.currentTarget);
    }

    this.rootLinks.addClass('faded');
    target.removeClass('faded');

    const children = $(`#${target.data('target')}`);

    if (children.length && !this.menuToggle.is(':visible')) {
      this.primaryNav.find('> li > ul').removeClass('show');
      children.addClass('show');

      const childrenHeight = children.outerHeight();

      if (this.header.hasClass('shrink')) {
        this.header.outerHeight(this.shrinkHeight + childrenHeight);
        children.css({
          maxHeight: $(window).height() - this.shrinkHeight,
          overflow: 'auto'
        });
      } else {
        this.header.outerHeight(this.initHeight + childrenHeight);
        children.css({
          maxHeight: $(window).height() - this.initHeight,
          overflow: 'auto'
        });
      }
    }
  }

  endLinkFocus() {
    if (this.primaryNav.find('a:focus').length === 0) {
      this.rootLinks.removeClass('faded');
    }
  }

  closeSubNavs() {
    this.header.scrollTop(0);

    if (!this.menuToggle.is(':visible')) {
      this.primaryNav.find('> li > ul').removeClass('show');

      if (this.header.hasClass('shrink')) {
        this.header.outerHeight(this.shrinkHeight);
      } else {
        this.header.outerHeight(this.initHeight);
      }

      this.primaryNav.find('> li > ul')
        .css({
          maxHeight: 'none',
          overflow: 'visible'
        });
    }
  }

  headerReset() {
    this.header.removeClass('expand');
    this.header.outerHeight(this.initHeight);

    if (this.header.hasClass('shrink')) {
      this.header.outerHeight(this.shrinkHeight);
    } else {
      this.header.outerHeight(this.initHeight);
    }

    this.menuToggle.removeClass('open');
    this.smallLogo.removeClass('show-flag');
    this.primaryNav.find('> li > ul').removeClass('show');
  }

  toggleMobileMenu() {
    const nav = this.header.find('nav');

    if (this.menuToggle.hasClass('open')) {
      this.menuToggle.removeClass('open');
      this.smallLogo.removeClass('show-flag');

      this.header.outerHeight(this.initHeight + this.header.find('nav').outerHeight());
      this.header.removeClass('expand');
      this.header.outerHeight(this.initHeight);
      nav.fadeOut(500, () => {
        nav.hide();
      });

      this.menuToggle.attr('aria-label', 'Open Menu');
    } else {
      this.menuToggle.addClass('open');
      this.smallLogo.addClass('show-flag');

      nav.show();

      this.header.outerHeight(this.initHeight + nav.outerHeight());
      nav.fadeIn(500);

      this.menuToggle.attr('aria-label', 'Close Menu');
    }
  }

  toggleMobileChildren(event) {
    const target = $(event.currentTarget);
    const children = target.siblings('ul');

    this.header.addClass('expand');

    target.toggleClass('open');
    children.slideToggle(400);

    if (target.hasClass('open')) {
      target.attr('aria-label', 'Hide Child Pages');
    } else {
      target.attr('aria-label', 'Show Child Pages');
    }
  }

  scrollToTop() {
    $('.scroll-to-top').on('click', () => {
      $("html, body").animate({ scrollTop: 0 }, "slow");
      return false;
    });

    $('.children-toggle').on('click', (e) => {
      $("html, body").animate({ scrollTop: $(e.currentTarget).offset().top }, "slow");
      return false;
    });
  }

  scrollEventInit() {
    let lastScrollTop = 0;

    let inHeader = false;

    this.header.on('mouseenter touchstart', () => {
      inHeader = true;
    });

    this.header.on('mouseleave', () => {
      inHeader = false;
    });

    const $window = $(window);
    $window.on('scroll', (e) => {
      var st = $window.scrollTop();

      if (!inHeader) {
        if ($window.scrollTop() > 200 && $window.width() >= 1110) {
          this.header.addClass('shrink');
          this.header.outerHeight(this.shrinkHeight);
        } else {
          this.header.removeClass('shrink');
          this.header.outerHeight(this.initHeight);
        }
      }

      lastScrollTop = st;
    });
  }
}
