export default class Accordion {
  constructor() {
    this.accordionToggle = $('.accordion__header');

    this.accordionToggle.on('click', (e) => {
      const target = $(e.currentTarget);
      this.toggleAccordion(target);
    });

    this.accordionToggle.keypress((e) => {
      if (e.keyCode === 13) {
        const target = $(e.currentTarget);
        this.toggleAccordion(target);
      }
    });
  }

  toggleAccordion(target) {
    const accordion = target.parent();

    if (accordion.hasClass('open')) {
      accordion.removeClass('open');
      accordion.find('.accordion__content').stop().slideUp(400);
    } else {
      $('.accordion').removeClass('open');
      $('.accordion__content').stop().slideUp(400);
      accordion.addClass('open');
      accordion.find('.accordion__content').stop().slideDown(400, function() {
        $('html, body').animate({ scrollTop: accordion.offset().top }), 400;
      });;
    }
  }
}