export default class Forms {
  constructor() {
    const container = $(".custom-select")
    const rfpForm = $("#rfp-search")
    const cards = $('[data-template="microsite"] .project-card')

    this.initOptions(container.length, container, rfpForm)

    this.inputs = $(".custom-form").find(
      'input[type="text"], input[type="email"], input[type="tel"], textarea, select',
    )

    this.checkInputs()

    this.inputs.on("blur", (e) => {
      const element = e.currentTarget
      this.checkInput(element)
    })

    $(".mc-field-group label").each((_, label) => {
      const labelText = label.innerText
      const input = label.nextElementSibling
      input.placeholder = labelText
    })
  }

  checkInputs() {
    this.inputs.each((i, input) => {
      if (this.hasPlaceholder(input)) {
        $(input).find("+ .floating-label").addClass("floating-label--above")
      }
    })
  }

  checkInput(element) {
    if (this.hasPlaceholder(element) || this.hasValue(element)) {
      $(element).find("+ .floating-label").addClass("floating-label--above")
    } else {
      $(element).find("+ .floating-label").removeClass("floating-label--above")
    }
  }

  hasPlaceholder(element) {
    if (element.getAttribute("placeholder")) {
      return true
    } else if (element.options && element.options[element.selectedIndex].text) {
      return true
    }

    return false
  }

  hasValue(element) {
    if (element.value) {
      return true
    }

    return false
  }

  initOptions(container_count, container, form) {
    let i
    let x
    let selection = []
    let dropdown = []
    let placeholder = []
    let cont = $(container)
    let dropdowns = cont.find(".select ul")
    for (i = 0; i < container_count; i++) {
      selection[i] = $(container[i]).next().next()
      placeholder[i] = $($(container[i]).find(".placeholder-text"))
      dropdown[i] = $(container[i]).find(".select ul")
      let options = $(container[i]).find(".select ul li")
      $(options[0]).hide()
      container[i].wrap = i
      cont[i].wrap = i
      $(cont[i]).on("click", (e) => {
        if (dropdown[e.currentTarget.wrap].hasClass("active")) {
          dropdown[e.currentTarget.wrap].toggleClass("active")
          $(container[e.currentTarget.wrap]).toggleClass("ontop")
        } else {
          dropdowns.removeClass("active")
          cont.removeClass("ontop")
          dropdown[e.currentTarget.wrap].addClass("active")
          $(container[e.currentTarget.wrap]).addClass("ontop")
        }
      })
      for (x = 0; x < options.length; x++) {
        options[x].wrap = i
        $(options[x]).on("click", (e) => {
          let attribute = $(e.currentTarget).attr("data-value")
          if ($(e.currentTarget).attr("data-name") != undefined) {
            let name = $(e.currentTarget).attr("data-name")
            selection[e.currentTarget.wrap].val(attribute)
            placeholder[e.currentTarget.wrap].html(name)
          } else {
            selection[e.currentTarget.wrap].val(attribute)
            placeholder[e.currentTarget.wrap].html(attribute)
          }
          $(dropdown[e.currentTarget.wrap]).find("li").show()
          $(e.currentTarget).hide()

          const micrositeCards = $(".project-card--microsite")
          if (micrositeCards.length && attribute) {
            micrositeCards.addClass("hide")

            $(
              `.project-card--microsite[data-status='${attribute}']`,
            ).removeClass("hide")
          } else if (micrositeCards.length) {
            micrositeCards.removeClass("hide")
          }

          form.submit()
        })
      }
    }
  }
}
