export class Sliders {
  constructor() {
    this.postCarousel = $('.news-posts__carousel');
    this.eventCarousel = $('.events__carousel');
    this.statisticsCarousel = $('.statistic__carousel');

    this.initSliders();
  }

  initSliders() {
    $('.project-carousel').each((i, carousel) => {
      const slides = $(carousel).find('.project-carousel__slides');
      const captions = $(carousel).find('.project-carousel__captions');

      const slidesId = slides.attr('id');
      const captionsId = captions.attr('id');

      $(`#${slidesId}`).slick({
        adaptiveHeight: true,
        asNavFor: `#${captionsId}`
      });

      $(`#${captionsId}`).slick({
        adaptiveHeight: true,
        swipe: false,
        fade: true,
        arrows: false,
        asNavFor: `#${slidesId}`
      });
    });

    this.postCarousel.on('init', () => {
      const carouselHeight = this.postCarousel.find('.slick-track').height();
      this.postCarousel.find('.news-post').outerHeight(carouselHeight);
    });
    this.postCarousel.on('setPosition', () => {
      this.setHeights();
    });

    this.postCarousel.slick({
      dots: true,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: true,
      mobileFirst: true,
      responsive: [
        {
          breakpoint: 699,
          settings: {
            centerMode: false,
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 1259,
          settings: {
            centerMode: false,
            slidesToShow: 3,
            slidesToScroll: 3
          }
        }
      ]
    });

    this.eventCarousel.on('init', () => {
      const carouselHeight = this.eventCarousel.find('.slick-track').height();
      this.eventCarousel.find('.event').outerHeight(carouselHeight);
    });
    this.eventCarousel.on('setPosition', () => {
      this.setHeights();
    });

    this.eventCarousel.slick({
      dots: true,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: true,
      mobileFirst: true,
      responsive: [
        {
          breakpoint: 699,
          settings: {
            centerMode: false,
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 1259,
          settings: {
            centerMode: false,
            slidesToShow: 3,
            slidesToScroll: 3
          }
        }
      ]
    });

    this.statisticsCarousel.slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1
    });

    $('.news-posts--home .slick-prev').on('click', () => {
      this.postCarousel.slick('slickPrev');
    });
    $('.news-posts--home .slick-next').on('click', () => {
      this.postCarousel.slick('slickNext');
    });

    $('.events .slick-prev').on('click', () => {
      this.eventCarousel.slick('slickPrev');
    });
    $('.events .slick-next').on('click', () => {
      this.eventCarousel.slick('slickNext');
    });

    $(window).on('resize', () => {
      this.setHeights();
    });
  }

  setHeights() {
    this.postCarousel.find('.news-post').height('auto');
    this.eventCarousel.find('.event').height('auto');

    const postCarouselHeight = this.postCarousel.find('.slick-track').height();
    this.postCarousel.find('.news-post').outerHeight(postCarouselHeight);

    const eventCarouselHeight = this.eventCarousel.find('.slick-track').height();
    this.eventCarousel.find('.event').outerHeight(eventCarouselHeight);
  }
}

export class HomeSlider {
  constructor() {
    const homeCarousel = $('.home__carousel');
    const leftSlides = $('.statistic--left');
    const rightSlides = $('.statistic--right');
    const displayed = $('.show-slide');
    const slides = $.merge($('.statistic--left'), $('.statistic--right'));

    if (homeCarousel.length > 0) {
      if ($(window).width() > 500) {
        this.timing(leftSlides);
        this.timing(rightSlides);
      } else {
        this.timing(slides);
      }
      $( window ).resize(() => {
        if ($(window).width() > 500) {
          $('.hide-slide').removeClass('hide-slide');
          this.stop();
          this.timing(leftSlides);
          this.timing(rightSlides);
        } else {
          displayed.eq(1).addClass('hide-slide');
          this.stop();
          this.timing(slides);
        }
      });
    }
  }

  stop() {
    var highestTimeoutId = setTimeout(";");
    for (var i = 0 ; i < highestTimeoutId ; i++) {
        clearTimeout(i);
    }
  }

  timing(slides) {
    setInterval(() => {
      $('.hide-slide').removeClass('hide-slide');
      var index = 0;

      slides.each((i, slide) => {
        if ($(slide).hasClass('show-slide')) {
          index = i;
        }
      });

      this.show(slides, index);
    }, 3600);
  }

  show(slides, index) {
    if (index === slides.length - 1) {
      index = 0;
    } else {
      index++;
    }

    $(slides).removeClass('show-slide');
    $(slides).eq(index).addClass('show-slide');
  }

}