export default class Project {
  constructor() {
    this.detached = [];

    const detachedElements = $('.project-content__right').find('> div:not(.copy, .links, .form-block)');
    detachedElements.each((i, element) => {
      this.detached.push({
        obj: element,
        prev: $(element).prev()
      });
    });

    $(window).on('load resize', () => {
      this.moveMedia();
    });
  }

  moveMedia() {
    if ($(window).width() >= 840) {
      if ($('.project-content__left').is(':empty')) {
        this.detached.forEach((data) => {
          const obj = $(data.obj);
          obj.detach();
          $('.project-content__left').append(obj);
        });
      }
    } else if (!$('.project-content__left').is(':empty')) {
      this.detached.forEach((data) => {
        const obj = $(data.obj);
        obj.detach();

        if (data.prev.length > 0) {
          $(data.prev).after(obj);
        } else {
          $('.project-content__right').prepend(obj);
        }
      });
    }
    $('.project-content__left').css('visibility', 'visible');
    $('.project-content__right').css('visibility', 'visible');
  }
}
